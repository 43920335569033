@import 'Styles/Base/includes';

/**
*
* Wysiwyg
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Wysiwyg {
    font-size: 1.6rem;
    line-height: 1.6;

    //font-family: Arial, sans-serif;
    font-display: block;

    @include media(sm) {
        font-size: 1.8rem;
    }

    // If aligned image is in bottom
    &::after {
        content: '';
        display: block;
        clear: both;
    }

    p {
        margin-top: 15px;
        margin-bottom: 15px;

        &:first-of-type {
            margin-top: 0;
        }
    }

    &--Sidebar {
        p {
            margin-top: 0;
            font-size: 1.6rem;
        }
    }

    &--LinkBlurb {
        p {
            margin-top: 0;
        }
    }

    :global {
        .figure {
            &--image-full {
                margin-top: 24px;
                margin-bottom: 24px;

                @include media(sm) {
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
            }
        }
    }

    ul,
    ol {
        margin-top: 20px;
        margin-bottom: 20px;

        @include media(sm) {
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    blockquote,
    &__Factbox {
        margin-top: 25px;
        margin-bottom: 25px;

        @include media(sm) {
            margin-top: 35px;
            margin-bottom: 35px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        clear: both;
        margin-top: 15px;
        margin-bottom: 5px;
        font-weight: $bold;
        line-height: 1.3;

        @include media(sm) {
            margin-top: 30px;
        }

        + p,
        + ol,ul {
            margin-top: 2px;
        }
    }

    h1, h2 {
        font-size: 2.8rem;

        + p,
        + ol,ul {
            margin-top: 10px;
        }
    }

    h3 {
        font-size: 2.4rem;
    }

    h4 {
        font-size: 2.2rem;
    }

    h5 {
        font-size: 2rem;
    }

    h6 {
        font-size: 1.8rem;
    }

    h3,h4,h5,h6 {
        margin-bottom: 10px;
    }

    strong,
    b {
        font-weight: $bold;
    }

    i,
    em {
        font-style: italic;
    }

    a {
        cursor: pointer;

        &:hover {
            text-decoration: underline;

            @media(hover: none) {
                text-decoration: none;
            }
        }

        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".docx"],
        &[href$=".ppt"],
        &[href$=".pptx"],
        &[href$=".xls"],
        &[href$=".xlsx"],
        &[href$=".odt"],
        &[href$=".eps"],
        &[href$=".zip"] {
            display: inline-block;
            // width: 100%;
            position: relative;
            padding: 15px 20px 15px 50px;
            color: $text-color;

            @include media(sm) {
                padding: 15px 25px 15px 55px;
            }

            &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 20px;
                display: inline-block;
                width: 16px;
                height: 20px;
                background-repeat: no-repeat;
                background-size: contain;
                transform: translateY(-50%);

                @include media(sm) {
                    left: 25px;
                }
            }

            &[href$=".pdf"]::after {
                content: " (pdf)";
            }

            &[href$=".doc"]::after {
                content: " (doc)";
            }

            &[href$=".docx"]::after {
                content: " (docx)";
            }

            &[href$=".zip"]::after {
                content: " (zip)";
            }
        }
    }

    img {
        display: block;
        max-width: 100%;
        height: auto;
    }

    :global {
        .figure {
            display: inline-block;

            &--image-full {
                display: block;
            }

            @include media(xs) {
                &--image-left,
                &--image-right {
                    width: 60%;
                    max-width: 350px;
                }

                &--image-thumbnail-left,
                &--image-thumbnail-right {
                    width: 150px;
                }

                &--image-left,
                &--image-thumbnail-left {
                    float: left;
                    margin-right: 15px;
                }

                &--image-right,
                &--image-thumbnail-right {
                    float: right;
                    margin-left: 15px;
                }
            }

            @include media(sm) {
                &--image-left,
                &--image-thumbnail-left {
                    float: left;
                    margin-right: 25px;
                }

                &--image-right,
                &--image-thumbnail-right {
                    float: right;
                    margin-left: 25px;
                }
            }

            figcaption {
                margin-top: 5px;
                margin-bottom: 8px;
                padding-bottom: 11px;
                font-size: 1.4rem;
                border-bottom: 1px solid black;
            }

            &--image-thumbnail {
                figcaption {
                    display: none;
                }
            }
        }
    }

    ol,
    ul {
        clear: both;
    }

    li {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    ul li {
        padding-left: 22px;
        position: relative;

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 10px;
            left: 0;
            width: 5px;
            height: 5px;
            border-radius: 20px;
            background: $text-color;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
        }
    }

    ol {
        margin-left: 17px;
        list-style-type: decimal;
    }

    ol li {
        position: relative;
        display: list-item;
        padding-left: 5px;
    }

    blockquote {
        position: relative;
        padding: 20px 0 0 0;
        clear: both;
        font-size: 2.2rem;
        line-height: 1.4;
        font-weight: $bold;

        @include media(xs) {
            padding-top: 30px;
            font-size: 2.8rem;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 2px;
        }

        &[cite] {
            &:after {
                content: attr(cite);
                display: block;
                padding-top: 10px;
                padding-bottom: 5px;
                font-size: 1.6rem;
                font-weight: $normal;

                @include media(xs) {
                    padding-top: 15px;
                    font-size: 1.8rem;
                }
            }
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }

    :global {
        .responsive-object {
            clear: both;
            position: relative;

            iframe,
            object,
            embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__Factbox {
        clear: both;
        padding: 35px 20px;
        background-color: lightgrey;

        @include media(sm) {
            padding: 40px;
        }

        > h2,
        > h3,
        > h4 {
            margin-top: 0;
            color: inherit !important;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &__VideoMessage {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
    }
}
