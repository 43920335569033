@import 'Styles/Tillskararakademin/includes';

/**
*
* Wysiwyg
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Wysiwyg.Base.module.scss';

.Wysiwyg {
    p {
        color: palette(black, 22);
    }

    ul,
    ol {
        @include media(sm) {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-top: 20px;
        margin-bottom: 5px;
        font-family: $font-heading;
    }

    h2 {
        font-size: 3rem;
    }

    h3 {
        font-size: 2.6rem;

        + p,
        + ol,ul {
            margin-top: 20px;
        }
    }

    h4 {
        font-size: 2.4rem;
    }

    h5 {
        font-size: 2.2rem;
    }

    h6 {
        font-size: 2rem;
    }

    a {
        color: palette(green);
        border-bottom: 2px solid palette(green);
        display: inline;
        align-items: center;

        &:hover {
            text-decoration: none;
            border-color: transparent;
        }

        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".docx"],
        &[href$=".ppt"],
        &[href$=".pptx"],
        &[href$=".xls"],
        &[href$=".xlsx"],
        &[href$=".odt"],
        &[href$=".eps"],
        &[href$=".zip"] {
            padding: 0 0 0 30px;
            border-bottom: none;
            border-radius: 4px;
            transition: background .2s;

            @include media(sm) {
                padding: 0 0 0 35px;
            }

            &::before {
                left: 5px;
                width: 18px;
                height: 18px;
                background-image: url('#{$basepath}img/bg-icons/tillskararakademin/download.svg');
                border-bottom: 2px solid palette(black);
            }

            &:hover::before {
                background-image: url('#{$basepath}img/bg-icons/tillskararakademin/download--green.svg');
                border-color: palette(green);
            }

            &::after {
                margin-left: 5px;
            }
        }
    }

    :global {
        .figure {
            figcaption {
                margin-bottom: 24px;
                padding-bottom: 11px;
                color: #444;
                font-size: 1.4rem;
                border-color: palette(black, 10);
            }

            &--image-thumbnail {
                figcaption {
                    display: none;
                }
            }
        }
    }

    ol,
    ul {
        list-style-position: outside;
    }

    ul {
        list-style-type: circle;
        list-style-image: url('#{$basepath}img/bg-icons/tillskararakademin/dot--green.svg');
        margin-left: 15px;
    }

    li {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 16px;
        color: palette(black, 22);

        @include media(sm) {
            font-size: 18px;
        }
    }

    ul li {
        padding-left: 8px;

        &::before {
            display: none;
        }
    }

    ol li {
        padding-left: 8px;
    }

    blockquote {
        font-size: 3rem;
        font-family: $font-heading;
        line-height: 43px;
        color: palette(black);
        border-top: 2px solid palette(black, 5);

        @include media(xs) {
            font-size: 3.6rem;
        }

        &::before {
            content: '"';
            position: relative;
            width: auto;
            height: auto;
        }

        &::after {
            content: '"';
        }
    }

    &__Factbox {
        background-color: palette(black, 3);
        color: $text-color;

        @include media(sm) {
            padding: 45px;
        }
    }
}
