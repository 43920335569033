@import 'Styles/Pahlmans/includes';

/**
*
* Wysiwyg
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Wysiwyg.Base.module.scss';

.Wysiwyg {
    color: palette(black, 80);

    :global {
        .figure {
            &--image-full {
                @include media(sm) {
                    margin-top: 40px;
                    margin-bottom: 40px;
                }
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: $medium;
        font-family: $font-heading;
        color: black;
    }

    a {
        color: $primary-color;
        text-decoration: underline;

        &:hover {
            color: $text-color;

            @media(hover: none) {
                text-decoration: none;
            }
        }

        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".docx"],
        &[href$=".ppt"],
        &[href$=".pptx"],
        &[href$=".xls"],
        &[href$=".xlsx"],
        &[href$=".odt"],
        &[href$=".eps"],
        &[href$=".zip"] {
            padding: 0 20px 0 25px;
            font-weight: $semibold;
            text-decoration: none;

            @include media(sm) {
                padding: 0 25px;
            }

            &:hover {
                color: $primary-color;

                &::before {
                    filter: brightness(1);
                    opacity: 1;
                }
            }

            &::before {
                left: 0;
                display: inline-block;
                width: 16px;
                height: 20px;
                background-image: $download;
                filter: brightness(0.2);
                opacity: 0.8;

                @include media(sm) {
                    left: 0;
                }
            }
        }
    }

    img {
        & + span {
          font-size: 1.4rem;
          padding: 5px 0 0 0;
          display: inline-block;
          color: palette(black, 78);
        }
    }

    :global {
        .figure {
            figcaption {
                margin-bottom: 24px;
                color: palette(black, 78);
                border-color: palette(black, 15);
            }

            &--image-thumbnail {
                figcaption {
                    display: none;
                }
            }
        }
    }

    ol,
    ul {
        line-height: 1.3;
    }

    ol {
        margin-left: 0;
        list-style-type: none;
        counter-reset: list-counter;
    }

    ol li {
        padding-left: 25px;

        &::before {
            content: counter(list-counter) '.';
            counter-increment: list-counter;
            position: absolute;
            left: 0;
        }
    }

    blockquote {
        padding: 0 0 0 30px;
        color: $primary-color;
        font-weight: $semibold;

        @include media(xs) {
            padding-top: 0;
        }

        &::before {
            width: 3px;
            height: 100%;
            background: $primary-color;
        }

        &[cite] {
            &:after {
                padding-top: 30px;
                padding-bottom: 8px;
                color: palette(black, 80);

                @include media(xs) {
                    padding-top: 50px;
                }
            }
        }
    }

    > *:last-child {
        margin-bottom: 0;
    }

    :global {
        .responsive-object {
            clear: both;
            position: relative;

            iframe,
            object,
            embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    &__Factbox {
        background-color: $secondary-color;
        color: white;
    }
}
